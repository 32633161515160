import { createAction, props } from '@ngrx/store';
import { ApiError, AuthUser } from '@corezilla/api';

/**
 * Action to login current user
 * **/
export const login = createAction(
  '[Auth] Login',
  props<{ email: string; password: string; rememberMe?: boolean }>()
);

/**
 * Action to set user after successful login
 * **/
export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ user: AuthUser }>()
);

/**
 * Action to set error after failed login
 * **/
export const loginError = createAction(
  '[Auth] Login Error',
  props<{ error: ApiError }>()
);

/**
 * Action to logout current user
 **/
export const logout = createAction('[Auth] Logout');

/**
 *
 **/
export const logoutSuccess = createAction('[Auth] Logout Success');

/**
 *
 **/
export const logoutError = createAction(
  '[Auth] Logout Error',
  props<{ error: ApiError }>()
);

/**
 * Action to remove auth user from state
 **/
export const removeAuthUser = createAction(
  '[Auth] Remove User',
  props<{ skipRedirect: boolean }>()
);

/**
 * Action to update password of current user
 **/
export const updateAuthUserPassword = createAction(
  '[Auth] Update Password',
  props<{ oldPassword: string; newPassword: string }>()
);

/**
 * Action to update user
 **/
export const updateAuthUser = createAction(
  '[Auth] Update User',
  props<{ toUpdate: AuthUser }>()
);

/**
 *
 **/
export const updateAuthUserSuccess = createAction(
  '[Auth] Update User success',
  props<{ updated: AuthUser }>()
);

/**
 *
 **/
export const updateAuthUserError = createAction(
  '[Auth] Update User error',
  props<{ error: ApiError }>()
);

/**
 * Action to set the current user
 **/
export const setAuthUser = createAction(
  '[Auth] Set User',
  props<{ user: AuthUser }>()
);

/**
 * Action to set error
 **/
export const setAuthError = createAction(
  '[Auth] Set Error',
  props<{ error: ApiError }>()
);
